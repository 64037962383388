.vehicles-progress-labels .vehicles-progress-label {
  position: relative;
  padding-bottom: 15px;
}
.vehicles-progress-labels .vehicles-progress-label::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: inline-block;
  height: 10px;
  width: 2px;
}
[dir=rtl] .vehicles-progress-labels .vehicles-progress-label::after {
  left: unset;
  right: 0;
}

.vehicles-overview-progress .bg-gray-900 {
  color: #fff;
}

#shipmentStatisticsChart .apexcharts-legend-series,
#carrierPerformance .apexcharts-legend-series {
  padding: 5px 15px;
  border-radius: 0.375rem;
  height: 83%;
}

.light-style .vehicles-progress-labels .vehicles-progress-label::after {
  background-color: #dbdade;
}
.light-style .vehicles-overview-progress .bg-gray-900 {
  background-color: #5d596c;
}
.light-style #shipmentStatisticsChart .apexcharts-legend-series,
.light-style #carrierPerformance .apexcharts-legend-series {
  border: 1px solid #dbdade;
}

.dark-style .vehicles-progress-labels .vehicles-progress-label::after {
  background-color: #434968;
}
.dark-style .vehicles-overview-progress .bg-gray-900 {
  background-color: #4a5072;
}
.dark-style #shipmentStatisticsChart .apexcharts-legend-series,
.dark-style #carrierPerformance .apexcharts-legend-series {
  border: 1px solid #434968;
}
