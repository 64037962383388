.dflexbetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    margin: 0px;
    padding: 20px 10px;
}
.dflexbetween img{
    width: 150px;
}
.dflexbetween h6 ,p{
    margin:4px 0;
}
.dflexbetween .divdetail{
    text-align: right;
}
.main-totalgrand{
    display: flex;
    justify-content: flex-end;
}
.totalgrand{
    margin-top: 20px;
}
.totalgrand div{
    background-color: lightgray;
    text-align: right;
    margin: 10px 0;
    padding: 10px;
    width: 300px;
}