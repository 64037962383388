.exportFile{
     display: flex;
     justify-content: space-between;
     margin: 2px 5px 0 5px;
}
.exportFile .fileDownload{
    display: flex;
    align-items: center;
}
/* .TeachersList{
    border-bottom: 2px solid goldenrod;
}
.TeachersList h5{
    margin: 0;
    padding:10px 30px;
} */