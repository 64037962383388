table.dataTable tr.dtrg-group th {
  background-color: rgba(0, 0, 0, 0.1);
  text-align: left;
}

table.dataTable tr.dtrg-group.dtrg-level-0 th {
  font-weight: bold;
}

table.dataTable tr.dtrg-group.dtrg-level-1 th,
table.dataTable tr.dtrg-group.dtrg-level-2 th,
table.dataTable tr.dtrg-group.dtrg-level-3 th,
table.dataTable tr.dtrg-group.dtrg-level-4 th,
table.dataTable tr.dtrg-group.dtrg-level-5 th {
  background-color: rgba(0, 0, 0, 0.05);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 2em;
  font-size: 0.9em;
}

table.dataTable tr.dtrg-group.dtrg-level-2 th {
  background-color: rgba(0, 0, 0, 0.01);
  padding-left: 2.5em;
}

table.dataTable tr.dtrg-group.dtrg-level-3 th {
  background-color: rgba(0, 0, 0, 0.01);
  padding-left: 3em;
}

table.dataTable tr.dtrg-group.dtrg-level-4 th {
  background-color: rgba(0, 0, 0, 0.01);
  padding-left: 3.5em;
}

table.dataTable tr.dtrg-group.dtrg-level-5 th {
  background-color: rgba(0, 0, 0, 0.01);
  padding-left: 4em;
}

html.dark table.dataTable tr.dtrg-group th {
  background-color: rgba(255, 255, 255, 0.1);
}

html.dark table.dataTable tr.dtrg-group.dtrg-level-1 th {
  background-color: rgba(255, 255, 255, 0.05);
}

html.dark table.dataTable tr.dtrg-group.dtrg-level-2 th,
html.dark table.dataTable tr.dtrg-group.dtrg-level-3 th,
html.dark table.dataTable tr.dtrg-group.dtrg-level-4 th,
html.dark table.dataTable tr.dtrg-group.dtrg-level-5 th {
  background-color: rgba(255, 255, 255, 0.01);
}

table.dataTable.table-striped tr.dtrg-level-0 {
  background-color: rgba(0, 0, 0, 0.1);
}

table.dataTable.table-striped tr.dtrg-level-1 {
  background-color: rgba(0, 0, 0, 0.05);
}

table.dataTable.table-striped tr.dtrg-level-2,
table.dataTable.table-striped tr.dtrg-level-3,
table.dataTable.table-striped tr.dtrg-level-4,
table.dataTable.table-striped tr.dtrg-level-5 {
  background-color: rgba(0, 0, 0, 0.01);
}

table.dataTable.table-striped tr.dtrg-level-1 tr.dtrg-level-2 th,
table.dataTable.table-striped tr.dtrg-level-3 th,
table.dataTable.table-striped tr.dtrg-level-4 th,
table.dataTable.table-striped tr.dtrg-level-5 th {
  background-color: transparent;
}

.light-style tr.group,
.light-style tr.group:hover {
  background-color: rgba(75, 70, 92, 0.05) !important;
}

.dark-style tr.group,
.dark-style tr.group:hover {
  background-color: rgba(134, 146, 208, 0.1) !important;
}
