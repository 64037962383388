.circle-bullets {
  list-style-type: none;
}

.pricing-plans-comparison .table tr > th:first-child,
.pricing-plans-comparison .table tr > td:first-child {
  white-space: nowrap;
  text-align: start;
}
.pricing-plans-comparison .table tbody tr:last-child td {
  border-bottom: 0;
}

.price-yearly-toggle {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .pricing-free-trial img {
    position: absolute;
    bottom: 0;
    inset-inline-end: 0;
    height: 110%;
    width: auto;
  }
}
