.timetableDiv{
    text-align: center;
}
.timetableDiv p{
    margin: 2px 0;
    font-size: 12px;

}

.timetableDiv h6{
    margin: 5px 0;
    font-size: 14px;
    font-weight: 600;

}