.faq-header .input-wrapper {
  position: relative;
  width: 100%;
  max-width: 55%;
}
@media (max-width: 575.98px) {
  .faq-header .input-wrapper {
    max-width: 70%;
  }
}

.faq-banner-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.light-style .bg-faq-section {
  background-color: rgba(75, 75, 75, 0.04);
}

.dark-style .bg-faq-section {
  background-color: rgba(134, 146, 208, 0.04);
}
