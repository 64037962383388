.MessagetabsBox .MessagetabsBox {
    display: flex;
    justify-content: space-evenly;
}

.MessagetabsBox .MessagetabsBox .Message-lefttabs {
    width: auto;
    /* border-bottom: 1px solid lightgray; */
    display: block;
    flex-direction: row;
    overflow: auto;
    scrollbar-width: none;
    scrollbar-color: #888 #f1f1f1;
    width: 25%;
}

.Message-lefttabs h5{
    border-bottom: 1px solid rgb(225, 221, 221);
    padding-bottom: 5px;
}


.Message-lefttabs .base-TabsList-root {
    display: block;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.Message-tab2 {
    padding: 10px;
    width: 190px;
    background-color: #ffffff;
    /* border: 1px solid #7367f0; */
    border: none;
    /* margin: 20px 20px 0 0; */
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    color: rgb(110, 107, 107);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}



.Message-tab2:hover {
    color: #7367f0;
}

.Message-tab2 .manageicon {
    margin-right: 5px;
}

.Message-TabPanelDiv {
    width: 75%;
}