#icons-container .icon-card {
  width: 128px;
}
#icons-container .icon-card i {
  font-size: 2rem;
}

@media (max-width: 1024px) {
  #icons-container .icon-card {
    width: 126px;
  }
}
@media (max-width: 767.98px) {
  #icons-container .icon-card {
    width: 131px;
  }
}
@media (max-width: 414px) {
  #icons-container .icon-card {
    width: 110px;
  }
}
@media (max-width: 375px) {
  #icons-container .icon-card {
    width: 150px;
  }
}
