.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.toast-message {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.toast-message a {
  color: #7367f0;
}
.toast-message a:hover {
  color: rgba(115, 103, 240, 0.6);
}

.toast-title {
  margin-left: 0.75rem;
  color: #5d596c;
  margin-bottom: 0.25rem;
}
[dir=rtl] .toast-title {
  margin-right: 0.75rem;
}

#toast-container > div {
  padding: 20px 30px 20px 50px !important;
  opacity: 1;
}
[dir=rtl] #toast-container > div {
  direction: rtl;
  background-position: top 0.9rem right 1.25rem;
  padding: 20px 50px 20px 30px !important;
}
[dir=rtl] #toast-container.toast-top-left {
  right: 12px;
  left: auto;
}
[dir=rtl] #toast-container.toast-top-right {
  left: 12px;
  right: auto;
}
[dir=rtl] #toast-container.toast-bottom-left {
  right: 12px;
  left: auto;
}
[dir=rtl] #toast-container.toast-bottom-right {
  left: 12px;
  right: auto;
}
[dir=rtl] #toast-container.toast-top-full-width#toast-container > div, [dir=rtl] #toast-container.toast-bottom-full-width#toast-container > div {
  left: 0;
  right: 0;
}
#toast-container > .toast {
  max-width: 100%;
  background-size: 28px;
  background-repeat: no-repeat;
}
#toast-container.toast-bottom-full-width > div, #toast-container.toast-top-full-width > div {
  width: 100%;
  margin-bottom: 0;
  border-radius: 0 !important;
}
#toast-container > .toast-info {
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='26' height='26' rx='6' fill='%2300CFE8' fill-opacity='0.08'/%3E%3Ccircle cx='13' cy='13' r='6.75' stroke='%2300CFE8' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.0002 10H13.0077' stroke='%2300CFE8' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.25 13H13V16H13.75' stroke='%2300CFE8' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-position: 1.25rem 1.25rem;
}
#toast-container > .toast-error {
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='26' height='26' rx='6' fill='%23EA5455' fill-opacity='0.08'/%3E%3Cpath d='M17.5 8.5L8.5 17.5' stroke='%23EA5455' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.5 8.5L17.5 17.5' stroke='%23EA5455' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-position: 1.25rem 1.25rem;
}
#toast-container > .toast-success {
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='26' height='26' rx='6' fill='%2328C76F' fill-opacity='0.08'/%3E%3Cpath d='M7.75 13L11.5 16.75L19 9.25' stroke='%2328C76F' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-position: 1.25rem 1.25rem;
}
#toast-container > .toast-warning {
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='26' height='26' rx='6' fill='%23FF9F43' fill-opacity='0.08'/%3E%3Cpath d='M13 10V12.8362' stroke='%23FF9F43' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13 15.6543L13 15.6899' stroke='%23FF9F43' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.74941 18.2504H18.2494C18.7463 18.2469 19.2092 17.9976 19.4856 17.5846C19.7619 17.1716 19.8158 16.6485 19.6294 16.1879L14.3044 7.00038C14.0402 6.5229 13.5376 6.22656 12.9919 6.22656C12.4462 6.22656 11.9436 6.5229 11.6794 7.00038L6.35441 16.1879C6.17171 16.6377 6.218 17.1481 6.47865 17.5577C6.7393 17.9673 7.18207 18.2254 7.66691 18.2504' stroke='%23FF9F43' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-position: 1.25rem 1.25rem;
}

#toast-container:not(.toast-bottom-full-width, .toast-top-full-width) > div {
  width: 22em !important;
}

.toast-progress {
  position: absolute;
  bottom: auto;
  top: 0;
  opacity: 0.15;
  height: 0.1875rem;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
  filter: alpha(opacity=15);
}
[dir=rtl] .toast-progress {
  left: auto;
  right: 0;
}

.toast-close-button {
  position: absolute;
  right: 1.25rem !important;
  top: 0.5rem;
  text-shadow: none;
  color: #a5a3ae !important;
  padding: auto;
  font-size: 1.625rem;
}
[dir=rtl] .toast-close-button {
  left: 1.25rem !important;
  right: auto !important;
}

.light-style #toast-container {
  z-index: 999999;
}
.light-style #toast-container .toast-close-button {
  font-weight: 300;
}
.light-style #toast-container > div {
  box-shadow: 0 0.25rem 1rem rgba(165, 163, 174, 0.45);
  border-radius: 0.375rem;
}
.light-style #toast-container > .toast-success,
.light-style #toast-container .toast-error,
.light-style #toast-container .toast-info,
.light-style #toast-container .toast-warning {
  color: #5d596c;
  background-color: #fff;
}

.dark-style #toast-container {
  z-index: 999999;
}
.dark-style #toast-container .toast-close-button {
  font-weight: 300;
  color: #7983bb !important;
}
.dark-style #toast-container .toast-title {
  color: #cfd3ec;
}
.dark-style #toast-container > div {
  box-shadow: 0 0.25rem 1rem rgba(15, 20, 34, 0.55);
  border-radius: 0.375rem;
}
.dark-style #toast-container .toast-progress {
  background-color: #fff;
}
.dark-style #toast-container > .toast-success,
.dark-style #toast-container .toast-error,
.dark-style #toast-container .toast-info,
.dark-style #toast-container .toast-warning {
  color: #cfd3ec;
  background-color: #2f3349;
}

@media (min-width: 241px) and (max-width: 480px) {
  #toast-container .toast-close-button {
    top: 0.3em;
  }
}
