@media (max-width: 575.98px) {
  .invoice-edit .invoice-preview-card .invoice-calculations,
  .invoice-add .invoice-preview-card .invoice-calculations {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .invoice-edit .repeater-title,
  .invoice-add .repeater-title {
    position: absolute;
    top: -1.75rem;
  }
}
.invoice-edit .invoice-preview-card .repeater-wrapper:not(:last-child),
.invoice-add .invoice-preview-card .repeater-wrapper:not(:last-child) {
  margin-bottom: 1.5rem;
}
@media print {
  .invoice-edit hr,
  .invoice-add hr {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
