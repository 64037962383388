.misc-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.misc-bg-wrapper {
  position: relative;
}
.misc-bg-wrapper img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}

@media (max-width: 1499.98px) {
  .misc-bg-wrapper img {
    height: 250px;
  }
  .misc-under-maintenance-bg-wrapper img {
    height: 270px !important;
  }
}
