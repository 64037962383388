.ui-timepicker-wrapper {
  max-height: 10rem;
  overflow-y: auto;
  margin: 0.125rem 0;
  background: #fff;
  background-clip: padding-box;
  outline: none;
}

.ui-timepicker-list {
  list-style: none;
  padding: 0.125rem 0;
  margin: 0;
}

.ui-timepicker-duration {
  margin-left: 0.25rem;
}
[dir=rtl] .ui-timepicker-duration {
  margin-left: 0;
  margin-right: 0.25rem;
}

.ui-timepicker-list li {
  padding: 0.25rem 0.75rem;
  margin: 0.125rem 0.875rem;
  white-space: nowrap;
  cursor: pointer;
  list-style: none;
  border-radius: var(--bs-border-radius);
}
.ui-timepicker-list li.ui-timepicker-disabled, .ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  background: #fff !important;
  cursor: default !important;
}

.light-style .ui-timepicker-wrapper {
  padding: 0.5rem 0;
  z-index: 1091;
  background: #fff;
  box-shadow: 0 0.25rem 1rem rgba(165, 163, 174, 0.45);
  border: 0px solid #dbdade;
  border-radius: 0.375rem;
}
.light-style .ui-timepicker-list li {
  color: #6f6b7d;
}
.light-style .ui-timepicker-list li:hover {
  background: rgba(115, 103, 240, 0.08);
  color: #7367f0;
}
.light-style .ui-timepicker-list li:not(.ui-timepicker-selected) .ui-timepicker-duration {
  color: #a5a3ae;
}
.ui-timepicker-list:hover .light-style .ui-timepicker-list li:not(.ui-timepicker-selected) .ui-timepicker-duration {
  color: #a5a3ae;
}
.light-style .ui-timepicker-list li.ui-timepicker-disabled,
.light-style .ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  background: #fff !important;
  color: #a5a3ae !important;
}

.dark-style .ui-timepicker-wrapper {
  border: 0px solid #434968;
  padding: 0.5rem 0;
  z-index: 1091;
  background: #2f3349;
  box-shadow: 0 0.25rem 1rem rgba(15, 20, 34, 0.55);
  border-radius: 0.375rem;
}
.dark-style .ui-timepicker-list li {
  color: #b6bee3;
}
.dark-style .ui-timepicker-list li:hover {
  background: rgba(115, 103, 240, 0.08);
  color: #7367f0;
}
.dark-style .ui-timepicker-list li:not(.ui-timepicker-selected) .ui-timepicker-duration {
  color: #7983bb;
}
.ui-timepicker-list:hover .dark-style .ui-timepicker-list li:not(.ui-timepicker-selected) .ui-timepicker-duration {
  color: #7983bb;
}
.dark-style .ui-timepicker-list li.ui-timepicker-disabled,
.dark-style .ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  color: #7983bb !important;
  background: #2f3349 !important;
}
