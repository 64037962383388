.profile-head {
    padding: 30px 0;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    /* background-image: url('../assets//images/pages/profile-banner.png'); */
    color: #fff;
    margin: -20px 30px 20px 30px;
    width: auto;
}

.image-content-center {
    border: 1px solid lightgray;
    border-radius: 6px;
    width: 320px;
    padding: 10px;

}

.image-content-center .preview img {
    width: 100%;
    border-radius: 6px;
}

.user-pro h5 {
    font-size: 26px;
    margin: 0;
    color: white;
}

.user-pro p {
    font-size: 15px;
}

.user-pro ul {
    list-style: none;
    padding: 0;
}

.user-pro ul li {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.user-pro ul li .icon {
    font-size: 34px;
    padding: 8px;
    background-color: rgb(223, 162, 47);
    color: black;
    border-radius: 6px;
    margin-right: 15px;

}


/*  */

.tabsBox .tabs .left-tabs {
    width: auto;
    /* border-bottom: 1px solid lightgray; */
    display: flex;
    flex-direction: row;
    overflow: auto;
    scrollbar-width:none;
    scrollbar-color: #888 #f1f1f1;
}



.left-tabs .base-TabsList-root {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.mange-tab2 {
    padding: 10px;
    width: 190px ;
    background-color: #ffffff;
    /* border: 1px solid #7367f0; */
    border: none;
    /* margin: 20px 20px 0 0; */
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    color: rgb(110, 107, 107);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}



.mange-tab2:hover {
    color: #7367f0;
}

.mange-tab2 .manageicon {
    margin-right: 5px;
}

.error_message_p{
    color: red;
    font-size: 13px;
    display: block;
}