.swiper {
  width: 100%;
  /* height: 100%; */
  height: 400px;
}
.swiper .swiper-slide {
  padding: 2rem 0;
  text-align: center;
  font-size: 1.5rem;
  background-color: #ecebed;
  background-position: center;
  background-size: cover;
}

#swiper-multiple-slides,
#swiper-3d-coverflow-effect {
  height: 300px;
}

#swiper-3d-coverflow-effect .swiper-slide {
  max-width: 300px;
}

#swiper-3d-cube-effect {
  max-width: 400px;
}

#swiper-3d-flip-effect {
  padding: 50px;
}

#swiper-gallery {
  height: 500px;
}
#swiper-gallery .gallery-top {
  height: 80%;
  width: 100%;
}
#swiper-gallery .gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
#swiper-gallery .gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
  cursor: pointer;
}
#swiper-gallery .gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
